import React from 'react';
import {APIResource} from "../Services/APIResource/APIResource";
import {DependentFieldsSelect} from "../Components/Parameter/DependentFieldsSelect";
import {ROLE} from "../Services/User/User";
import { BulkVerify } from '../Services/BulkActions/BulkVerify/BulkVerify';
import { userHasRoleADMIN } from '../Store/ParameterStore';

export const PARAMETER_TYPE_MODEL_TYPE                     = 1;
export const PARAMETER_TYPE_MODEL_BACKGROUND_TAG           = 2;
export const PARAMETER_TYPE_ADL1                           = 3;
export const PARAMETER_TYPE_ADL2                           = 4;
export const PARAMETER_TYPE_ADL3                           = 5;
export const PARAMETER_TYPE_MODEL_NATURE_STANDARD          = 6;
export const PARAMETER_TYPE_MODEL_NATURE_DETAILED          = 7;
export const PARAMETER_TYPE_CHAMPION_OR_CHALLENGER         = 8;
export const PARAMETER_TYPE_IN_HOUSE_OR_VENDOR             = 9;
export const PARAMETER_TYPE_MATERIALITY                    = 10;
export const PARAMETER_TYPE_METRICS                        = 11;
export const PARAMETER_TYPE_EXTERNAL_IMPACT                = 12;
export const PARAMETER_TYPE_RISK_CATEGORY                  = 13;
export const PARAMETER_TYPE_MICRO_RISK_CATEGORY            = 14;
export const PARAMETER_TYPE_MICRO_RISK_SUB_CATEGORY        = 15;
export const PARAMETER_TYPE_MODEL_USAGE                    = 16;
export const PARAMETER_TYPE_USE_FREQUENCY                  = 17;
export const PARAMETER_TYPE_APPROVAL_TYPE                  = 18;
export const PARAMETER_TYPE_CERTIFICATION_RESULT           = 19;
export const PARAMETER_TYPE_NOTICE_STATUS                  = 20;
export const PARAMETER_TYPE_NOTICE_BREACH                  = 21;
export const PARAMETER_TYPE_GLOBAL_USE                     = 22;
export const PARAMETER_TYPE_DETAILED_USE                   = 23;
export const PARAMETER_TYPE_OPERATIONAL_RISK_FLAG          = 24;
export const PARAMETER_TYPE_REVIEW_STATUS                  = 25;
export const PARAMETER_TYPE_REVIEW_TYPE                    = 26;
export const PARAMETER_TYPE_NOTICE_TYPE                    = 27;
export const PARAMETER_TYPE_FINDING_NOTICE_SEVERITY        = 28;
export const PARAMETER_TYPE_REVIEW_RESULT                  = 29;
export const PARAMETER_TYPE_MODEL_GROUP_ENTITY_OVERRIDE    = 30;
export const PARAMETER_TYPE_MODEL_TIER_RESULT              = 31;
export const PARAMETER_TYPE_MRA_STATUS                     = 32;
export const PARAMETER_TYPE_MODEL_STATUS                   = 33;
export const PARAMETER_TYPE_MODEL_DEVELOPMENT_USE          = 34;
export const PARAMETER_TYPE_MODEL_VALIDATOR_LOCATION       = 35;
export const PARAMETER_TYPE_MODEL_TIERING_CATEGORY         = 36;
export const PARAMETER_TYPE_WORKFLOW_TARGET_ENTITY         = 37;
export const PARAMETER_TYPE_WORKFLOW_EVENT                 = 38;
export const PARAMETER_TYPE_WORKFLOW_ACTION_TYPE           = 39;
export const PARAMETER_TYPE_SPECIAL_QUANTIFIED_MATERIALITY = 40;
export const PARAMETER_TYPE_IMPLEMENTATION_PLATFORM_IT     = 41;
export const PARAMETER_TYPE_WORKFLOW_PROCESS               = 42;
export const PARAMETER_TYPE_RECOMMENDATION_IGG_STATUS      = 43;
export const PARAMETER_TYPE_RECOMMENDATION_PRIORITY        = 44;
export const PARAMETER_TYPE_RECOMMENDATION_ORIGIN          = 45;
export const PARAMETER_TYPE_RECOMMENDATION_RESPONSIBLE     = 46;
export const PARAMETER_TYPE_RECOMMENDATION_RISK_CATEGORY   = 47;
export const PARAMETER_TYPE_TROUBLE_TYPE                   = 48;
export const PARAMETER_TYPE_MITIGATION_MEASURE_TYPE        = 49;
export const PARAMETER_TYPE_MITIGATION_DEFICIENCY_TYPE     = 50;
export const PARAMETER_TYPE_REVIEW_COMMITTEE_TYPE          = 51;
export const PARAMETER_TYPE_REVIEW_COMMITTEE_SUBTYPE       = 52;
export const PARAMETER_TYPE_DOCUMENT_CATEGORY              = 53;
export const PARAMETER_TYPE_MRA_PROCESS                    = 54;
export const PARAMETER_TYPE_MRA_SCORE_TYPE                 = 55;
export const PARAMETER_TYPE_NON_MODEL_STATUS               = 56;
export const PARAMETER_TYPE_MODEL_RETIREMENT_STATUS        = 58;
export const PARAMETER_TYPE_SPECIFIC_FRAMEWORK             = 59;
export const PARAMETER_TYPE_MRA_DIMENSION_SOURCE           = 60;
export const PARAMETER_TYPE_ITSYSTEM_TYPE                  = 61;
export const PARAMETER_TYPE_MODEL_RISK_ASSESSMENT_RESULT   = 62;
export const PARAMETER_TYPE_MODEL_OBSERVABILITY            = 63;
export const PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS    = 64;
export const PARAMETER_TYPE_FINDING_STATUS                 = 65;
export const PARAMETER_TYPE_REVIEW_SEGMENT                 = 66;
export const PARAMETER_TYPE_COMPLEXITY_LEVEL               = 67;
export const PARAMETER_TYPE_NOTICE_PERIMETER               = 68;
export const PARAMETER_TYPE_NOTICE_SEGMENT                 = 69;
export const PARAMETER_TYPE_NOTICE_ENTITY                  = 70;
export const PARAMETER_TYPE_REVIEW_SCOPE_OF_TESTING        = 71;
export const PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE   = 72;
export const PARAMETER_TYPE_MITIGATION_STATUS              = 73;
export const PARAMETER_TYPE_MODEL_POLICY_EXCEPTION_TYPE    = 74;
export const PARAMETER_TYPE_REVIEW_PLANNING_STATUS         = 75;
export const PARAMETER_TYPE_DOCUMENT_CATEGORY_FOR_MODEL    = 76;
export const PARAMETER_TYPE_VALIDATION_PLAN_ENTRYEXIT      = 77;
export const PARAMETER_TYPE_VALIDATION_PLAN_STATUS         = 78;
export const PARAMETER_TYPE_MRM_PERIMETER                  = 79;
export const PARAMETER_TYPE_MRASUBDIMENSION_REMEDIATION    = 80;
export const PARAMETER_TYPE_MRM_CATEGORY                   = 81;
export const PARAMETER_TYPE_MRM_SUBCATEGORY                = 82;

let typesMap = {
    PARAMETER_TYPE_MODEL_TYPE: 'Model type',
    PARAMETER_TYPE_MODEL_BACKGROUND_TAG: 'Background model tag',
    PARAMETER_TYPE_ADL1: 'Application domain level 1',
    PARAMETER_TYPE_ADL2: 'Application domain level 2',
    PARAMETER_TYPE_ADL3: 'Application domain level 3',
    PARAMETER_TYPE_MODEL_NATURE_STANDARD: 'Model nature standard',
    PARAMETER_TYPE_MODEL_NATURE_DETAILED: 'Model nature detailed',
    PARAMETER_TYPE_CHAMPION_OR_CHALLENGER: 'Champion or challenger',
    PARAMETER_TYPE_IN_HOUSE_OR_VENDOR: 'In house or vendor',
    PARAMETER_TYPE_MATERIALITY: 'Materiality',
    PARAMETER_TYPE_METRICS: 'Metrics',
    PARAMETER_TYPE_EXTERNAL_IMPACT: 'External impact',
    PARAMETER_TYPE_RISK_CATEGORY: 'Risk Category',
    PARAMETER_TYPE_MICRO_RISK_CATEGORY: 'Micro-Risk Category',
    PARAMETER_TYPE_MICRO_RISK_SUB_CATEGORY: 'Micro-Risk Sub-Category',
    PARAMETER_TYPE_MODEL_USAGE: 'Model Usage',
    PARAMETER_TYPE_USE_FREQUENCY: 'Use Frequency',
    PARAMETER_TYPE_APPROVAL_TYPE: 'Approval type',
    PARAMETER_TYPE_GLOBAL_USE: 'Global use',
    PARAMETER_TYPE_DETAILED_USE: 'Detailed use',
    PARAMETER_TYPE_OPERATIONAL_RISK_FLAG: 'Operational Risk Flag',
    PARAMETER_TYPE_REVIEW_STATUS: 'Review status',
    PARAMETER_TYPE_REVIEW_TYPE: 'Review type',
    PARAMETER_TYPE_NOTICE_TYPE: 'Notice type',
    PARAMETER_TYPE_FINDING_NOTICE_SEVERITY: 'Finding/Notice severities',
    PARAMETER_TYPE_REVIEW_RESULT: 'Review validation status',
    PARAMETER_TYPE_MODEL_GROUP_ENTITY_OVERRIDE: 'Model Group entity override',
    PARAMETER_TYPE_MODEL_TIER_RESULT: 'Model tier result',
    PARAMETER_TYPE_MRA_STATUS: 'MRA status',
    PARAMETER_TYPE_MODEL_STATUS: 'Model status',
    PARAMETER_TYPE_MODEL_DEVELOPMENT_USE: 'Model development and use',
    PARAMETER_TYPE_MODEL_VALIDATOR_LOCATION: 'Model validators location',
    PARAMETER_TYPE_MODEL_TIERING_CATEGORY: 'Model tiering category',
    PARAMETER_TYPE_WORKFLOW_TARGET_ENTITY: 'Workflow target entity',
    PARAMETER_TYPE_WORKFLOW_EVENT: 'Workflow event',
    PARAMETER_TYPE_WORKFLOW_ACTION_TYPE: 'Action type',
    PARAMETER_TYPE_SPECIAL_QUANTIFIED_MATERIALITY:
        'Special quantified materiality',
    PARAMETER_TYPE_IMPLEMENTATION_PLATFORM_IT: 'Platform system/IT',
    PARAMETER_TYPE_WORKFLOW_PROCESS: 'Workflow process',
    PARAMETER_TYPE_RECOMMENDATION_IGG_STATUS: 'Recommendation IGG status',
    PARAMETER_TYPE_RECOMMENDATION_PRIORITY: 'Recommendation priority',
    PARAMETER_TYPE_RECOMMENDATION_ORIGIN: 'Recommendation origin',
    PARAMETER_TYPE_RECOMMENDATION_RESPONSIBLE:
        'Recommendation responsible entity',
    PARAMETER_TYPE_RECOMMENDATION_RISK_CATEGORY: 'Recommendation risk category',
    PARAMETER_TYPE_TROUBLE_TYPE: 'Trouble type',
    PARAMETER_TYPE_MITIGATION_MEASURE_TYPE: 'Mitigation measure type',
    PARAMETER_TYPE_MITIGATION_DEFICIENCY_TYPE: 'Mitigation deficiency type',
    PARAMETER_TYPE_REVIEW_COMMITTEE_TYPE: 'Meeting type',
    PARAMETER_TYPE_REVIEW_COMMITTEE_SUBTYPE: 'Meeting sub-type',
    PARAMETER_TYPE_DOCUMENT_CATEGORY: 'Document category',
    PARAMETER_TYPE_MRA_PROCESS: 'MRA Process',
    PARAMETER_TYPE_MRA_SCORE_TYPE: 'MraScore type',
    PARAMETER_TYPE_NON_MODEL_STATUS: 'Non-model status',
    PARAMETER_TYPE_MRA_DIMENSION_SOURCE: 'Mra dimension source',
    PARAMETER_TYPE_MODEL_RETIREMENT_STATUS: 'Model retirement status',
    PARAMETER_TYPE_SPECIFIC_FRAMEWORK: 'Specific framework',
    PARAMETER_TYPE_ITSYSTEM_TYPE: 'ITSystem type',
    PARAMETER_TYPE_MODEL_RISK_ASSESSMENT_RESULT: 'Model risk assessment result',
    PARAMETER_TYPE_MODEL_OBSERVABILITY: 'Model observability',
    PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS: 'Use validation status',
    PARAMETER_TYPE_NOTICE_STATUS: 'Notice status',
    PARAMETER_TYPE_NOTICE_BREACH: 'Notice breach',
    PARAMETER_TYPE_FINDING_STATUS: 'Finding status',
    PARAMETER_TYPE_REVIEW_SEGMENT: 'Review segment',
    PARAMETER_TYPE_COMPLEXITY_LEVEL : 'Complexity level',
    PARAMETER_TYPE_NOTICE_PERIMETER: 'Notice perimeter',
    PARAMETER_TYPE_NOTICE_SEGMENT: 'Notice segment',
    PARAMETER_TYPE_NOTICE_ENTITY: 'Notice entity',
    PARAMETER_TYPE_REVIEW_SCOPE_OF_TESTING: 'Review scope of testing',
    PARAMETER_TYPE_TIERING_COMPLEXITY_RATIONALE: 'Tiering complexity rationale',
    PARAMETER_TYPE_MITIGATION_STATUS: 'Mitigation action status',
    PARAMETER_TYPE_MODEL_POLICY_EXCEPTION_TYPE: 'Policy Exception type',
    PARAMETER_TYPE_REVIEW_PLANNING_STATUS: 'Review planning status',
    PARAMETER_TYPE_DOCUMENT_CATEGORY_FOR_MODEL: 'Document category for model',
    PARAMETER_TYPE_VALIDATION_PLAN_ENTRYEXIT: 'Validation plan entry/exit values',
    PARAMETER_TYPE_VALIDATION_PLAN_STATUS: 'Validation plan current year status',
    PARAMETER_TYPE_MRM_PERIMETER: 'MRM Perimeter',
    PARAMETER_TYPE_MRASUBDIMENSION_REMEDIATION: 'MRA Subdimension Remediation',
    PARAMETER_TYPE_MRM_CATEGORY: 'MRM Category',
    PARAMETER_TYPE_MRM_SUBCATEGORY: 'MRM Subcategory',
};

typesMap = Object.fromEntries(Object.entries(typesMap).map(([k, v]) => {
    return [eval(k), v];
}));

export const ParameterTypesMap = typesMap;

export const ApiResourceDefaultParams = {
    id: "parameters",
    name: "Parameters",
    icon: "cog",
    fieldForTitle: "label",
};

export default class ParameterAdmin {
    constructor(){
        this.configure();
    }

    configure() {
        let parameters = new APIResource(ApiResourceDefaultParams);
        parameters
            .setFields({
                id: { title: "ID" },
                type: {
                    title: "Type",
                    type: "mapped",
                    params: { mapping: ParameterTypesMap },
                },
                label: { title: "Label" },
                systemId: { title: "System ID" },
                shortName: { title: "Short name" },
                verified: { title: "Verified", type: "bool" },
                dependentFields: {
                    title: "Dependent parameters",
                    edit: (field, value, onChange, entity, routeParams) => {
                        return (
                            <DependentFieldsSelect
                                field={field}
                                values={value}
                                onChange={onChange}
                            />
                        );
                    },
                    display: (field, value, entity, props) => {
                        return (
                            <DependentFieldsSelect
                                field={field}
                                values={value}
                                onChange={() => null}
                                readOnly={true}
                            />
                        );
                    },
                    displayList: () => null,
                    filter: () => null,
                    token: false,
                },
            })
            .setLayout({
                tabs: {
                    Parameters: {
                        rows: [
                            {
                                panels: {
                                    Properties: {
                                        cols: 3,
                                        fields: ["type", "label", "shortName", "verified"],
                                    },
                                    "Dependent fields": {
                                        cols: 9,
                                        fields: ["dependentFields"],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: ["id", "type", "label", "shortName", "verified", "systemId"],
                routeAccessControl: [ROLE.MRM],
            })
            .genInsertView({
                routeAccessControl: [ROLE.ADMIN],
                postSaveRedirect: 'detail',
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Parameter';
                },
            })
            .genEditView({
                routeAccessControl: [ROLE.ADMIN],
            })
            .genDetailView({
                routeAccessControl: [ROLE.MRM],
            })
            .allowDelete({
                itemAccessCondition: (entity) => !entity.system && userHasRoleADMIN(),
                returnPath: (entity) => {
                    return "/resource/parameters/list";
                },
            })
            .addBulkAction(BulkVerify, {
                resource: parameters,
                icon: 'check',
                resourceId : "parameters",
            });
    }
}
